// Fonts
@font-face {
	font-family: NeueHaas;
	src: url('/font/NeueHaasDisplayRoman.ttf');
}

@font-face {
	font-family: Poppins;
	src: url('/font/Poppins-Regular.ttf');
}

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// File Image Cropper
@import 'theme';

// File Image Cropper
@import 'file-image.scss';

// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';


.faded {
    opacity: 0.5;
}

.table-hover {
    cursor: pointer;
}

.pull-down {
    margin-top: auto;
}

#navbarSupportedContent .navbar-nav li {
    margin-right:30px;
}

.navbar-brand {
    width: 175px;
}

.alert ul {
    margin-bottom: 0;
}

h4 {
    border-bottom:1px solid #444;
    margin-bottom:25px;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-4 {
    flex: 4;
}

.text-sm {
    font-size: 0.8rem;
}