
.card {
    border-color: black;
    border-radius:11px;
    border-width: 2px;
    overflow: hidden;

    .card-header {
        background-color : rgb(203,188,166);
        border-radius:9px 9px 0 0;

        a {
            text-decoration: underline;
            font-weight: 600;

            &:hover {
                opacity: 0.75;
            }
        }
    }

    .card-footer {
        background-color : rgb(203,188,166);
    }

    .card-footer-2 {
        padding: 1.25rem;
    }

    .card-header-radiusless {
        border-radius: 0;
    }
}

.table-list {
    border-color: black;
    border-style: solid;
    border-width: 2px;
    border-radius:11px;
    overflow: hidden;

    table {
        border-radius:11px;
        margin-bottom: 0;
    }

    tr.card-header {
        background-color : rgb(203,188,166);
        border-radius:9px 9px 0 0;
        font-weight: 400 !important;
        font-size: 1.1rem;
        text-transform: uppercase;

        a {
            font-weight: 400;
            text-decoration: none;
        }

        th {
            border-top: 0px solid white !important;
        }
    }

    td {
        border-top: 1px solid black;
    }
}

#navbarSupportedContent {
    text-transform: uppercase;
}

.filters-section {
    .row {
        margin-top: 10px;
    }
}

.modal-header {
    .btn-outline-primary {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
}

#navbarDropdown {
    font-weight: 400;
    padding: 4px 20px 4px 20px;
    border-radius: 25px;
    font-size: 1rem;
    border:2px solid black;
}

h1 {
    text-transform: uppercase;
    font-size: 2.5rem;
}

.flower {
    font-size: 3rem;
    padding-left:80px;
    background-image: url('/img/flower.png');
    background-size: contain;
    background-position: 0% 50%;
    background-repeat: no-repeat;
}

.huge-text {
    font-size: 2.5rem;
}

.table-borderless {
    tr td {
        font-size: 0.8rem;
        padding: 0.5rem 0.75rem;
    }
}

#search-box {
    font-weight: 400;
    padding: 7px 20px 7px 20px;
    border-radius: 25px 25px 25px 25px;
    font-size: 1rem;
    border:2px solid black;
}

.filter-options {
    select.form-control {
        border:2px solid black;
        border-radius: 25px 25px 25px 25px;
    }
}

.filter-options {
    ul {
        margin-bottom: 0;
    }
}

.btn-secondary,
.btn-primary {
    border-radius: 15px;
    border:2px solid black;
    padding: 0.05rem 0.5rem;

    &:hover {
        border:2px solid black;
        opacity: 0.5;
    }
}

.btn-eye {
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    border-radius: 25px;
    border:2px solid black;
    padding: 7px 20px;
    font-size: 1.1rem;

    img {
        margin-right: 5px;
    }

    &:hover {
        opacity: 0.5;
    }
}

.btn-outline-danger {
    border-radius: 15px;
    padding: 0.05rem 0.5rem;
}

.btn-lg {
    font-weight: 400;
    padding:7px 40px;
    border-radius: 25px 25px 25px 25px;
    font-size: 1rem;
    text-transform: uppercase;
}

.btn-md {
    padding: 0.2rem 1.2rem;
    text-transform: uppercase;
}

.card-title {
    text-transform: uppercase;
}

.black-border {
    border-radius: 25px;
    border: 2px solid black;
    overflow: hidden;
}

form {
    h4 {
        border-radius: 25px;
        background-color: rgb(203,188,166);
        padding:7px 25px;
        margin-bottom: 40px;
    }

    .form-control {
        border:2px solid black;
        border-radius: 25px 25px 25px 25px;
    }
}


@media (max-width: 768px) {

    .flower {
        background-image: none;
        padding-left: 0;
    }

    #calendar-widget {
        margin-top:20px;
    }

    .table-list,
    .table-borderless {
        tr th {
            display: none;
        }

        tr td {
            display: block;
            border-top: 0px solid black;
        }
    }
}