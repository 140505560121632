cropper-canvas {
    margin: 0 auto;
}

.file-image-label {
    position: relative;
    overflow: hidden;

    &:hover .file-image-text {
        opacity: 1;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0);
    }
}

.file-image-text {
    z-index: 1;
    position: absolute;
    width: 100%;
    text-align: center;
    height: fit-content;
    font-size: medium;
    text-shadow: 0px 0px 3px black;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 0.6rem 1.15rem;
    color: white;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    transition: all 0.25s;
    pointer-events: none;
}

.file-image-thumbnail {
    max-width: 300px;
    max-height: 300px;
    min-width: 115px;
    min-height: 115px;
    cursor: pointer;
    transition: filter 0.25s;

    &:hover {
        -webkit-filter: brightness(.45);
        filter: brightness(.45);
    }
}

.file-image-input {
    display: none;
}

.file-image-modal {
    min-width: fit-content;
    min-height: fit-content;
    max-width: 1365px;
}

.size-error {
    display: none;
    padding: 1rem 0.5rem;
}

.cropper-image {
    display: block;
}

.transform-actions-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.transform-actions-container {
    .color-picker {
        height: 100%;
        width: 56px;
        padding: 0.15rem 0.2rem;
    }
}

.flip-vertical-image {
    transform: rotate(270deg);
}

.cancel-file-image-close {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.buttons-container {
    display: flex;
    align-items: center;
}